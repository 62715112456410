if (!String.prototype.replaceAll) {
 String.prototype.replaceAll = function(str, newStr) {
   // If a regex pattern
   if (
     Object.prototype.toString.call(str).toLowerCase() === "[object regexp]"
   ) {
     return this.replace(str, newStr);
   }

   // If a string
   return this.replace(new RegExp(str, "g"), newStr);
 };
}

function nameReplacer(name, match, odd = "") {
 var replaceData = [
   {
     string: "{$competitor2}",
     replace: "2"
     /*match && match.away
         ? match.away.length > 10
           ? match.away.substring(0, 10) + "."
           : match.away
         : "2"*/
   },
   {
     string: "{$competitor1}",
     replace: "1"
     /*match && match.home
         ? match.home.length > 10
           ? match.home.substring(0, 10) + "."
           : match.home
         : "1"*/
   },
   {
     string: "Player 2",
     replace:
       match && match.away
         ? match.away.length > 10
           ? match.away.substring(0, 10) + "."
           : match.away
         : "Player 2"
   },
   {
     string: "Player 1",
     replace:
       match && match.home
         ? match.home.length > 10
           ? match.home.substring(0, 10) + "."
           : match.home
         : "Player 1"
   },
   {
     string: "Team 2",
     replace: "Team 2"
     /* match && match.away
         ? match.away.length > 10
           ? match.away.substring(0, 10) + "."
           : match.away
         : "Team 2" */
   },
   {
     string: "Deplasman",
     replace: "2"
     /*match && match.away
         ? match.away.length > 10
           ? match.away.substring(0, 10) + "."
           : match.away
         : "Deplasman"*/
   },
   {
     string: "Dep.",
     replace: "2"
     /*match && match.away
         ? match.away.length > 10
           ? match.away.substring(0, 10) + "."
           : match.away
         : "Dep."*/
   },
   {
     string: "Team 1",
     replace: "1"
     /* match && match.home
         ? match.home.length > 10
           ? match.home.substring(0, 10) + "."
           : match.home
         : "Team 1"*/
   },
   {
     string: "Beraberlik",
     replace: "X"
     /* match && match.home
         ? match.home.length > 10
           ? match.home.substring(0, 10) + "."
           : match.home
         : "Team 1"*/
   },
   {
     string: "Draw",
     replace: "X"
     /* match && match.home
         ? match.home.length > 10
           ? match.home.substring(0, 10) + "."
           : match.home
         : "Team 1"*/
   },
   {
     string: "Ev.",
     replace: "1"
     /*match && match.home
         ? match.home.length > 10
           ? match.home.substring(0, 10) + "."
           : match.home
         : "Ev."*/
   },
   {
     string: "Ev Sahibi",
     replace: "1"
     /* match && match.home
         ? match.home.length > 10
           ? match.home.substring(0, 10) + "."
           : match.home
         : "Ev Sahibi"*/
   },
   {
     string: "{hv}",
     replace:
       match && match.score
         ? match.score.split(":")[0]
         : match.oddsInfo
         ? match.oddsInfo.split("-")[0].split(":")[0]
         : "XXX"
   },
   {
     string: "{av}",
     replace:
       match && match.score
         ? match.score.split(":")[1]
         : match.oddsInfo
         ? match.oddsInfo.split("-")[0].split(":")[1]
         : "XXX"
   },
   {
     string: "{t1}",
     replace: "1"
     /* match && match.home
         ? match.home.length > 10
           ? match.home.substring(0, 10) + "."
           : match.home
         : "Team 1"*/
   },
   {
     string: ":-)",
     replace: ""
   },
   {
     string: "(-)",
     replace: match && match.score ? "(" + match.score + ")" : ""
   },
   {
     string: "{t2}",
     replace: "2"
     /* match && match.away
         ? match.away.length > 10
           ? match.away.substring(0, 10) + "."
           : match.away
         : "Team 2"*/
   },
   {
     string: "{sw}",
     replace: odd && odd.sq ? odd.sq + "." : 0
   },
   {
     string: "{pw}",
     replace: odd && odd.sq ? odd.sq + "." : 0
   },
   {
     string: "{h}",
     replace: odd && odd.specialvalue ? odd.specialvalue : 0
   },
   {
     string: "{-h}",
     replace: odd && odd.specialvalue ? "-" + odd.specialvalue : 0
   }
 ];
 replaceData.forEach(r => {
   if (typeof name == "string" && name.includes(r.string))
     name = name.replaceAll(r.string, r.replace).trim();
 });
 return name;
}

export default nameReplacer;
