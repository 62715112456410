import React from 'react'
import { connect } from 'react-redux'
import { WebClient, constants } from '../../components/utils/webclient'

class Tombala extends React.Component {

  constructor(props) {
    super(props)
    this.webClient = new WebClient();
    this.state = {
      loading: true,
      gameUrl: ""
    }
  }

  componentDidMount() {
    this.startAction()
  }

  async startAction() {

    let a = await localStorage.getItem("bingoUrl")

    // // console.log("user id", a)

    if (a != null) {
      this.setState({loading: false, gameUrl: a})
    }

    // this._getBingoUrl()
  }

  _getBingoUrl() {
    this.webClient.post(constants.uris.bngo, 
      {
        token: Math.random().toString(36).substr(2)
      }, 
      {
        setReqId: true,
        setMachineId: false,
        setUserId: true,
        setLang: true
      }).then((response) => {
        // console.log(response)
        if (response.status) {

          let a = response?.content?.gameurl?.split("?token");

          if (a?.length > 0) {
            let gameUrl = `${a[0]}/#/secondScreen?token${a[1]}`
            // console.log(gameUrl)
            this.setState({gameUrl: gameUrl, loading: false})
          }

        }
      })
  }  

  render() {

    const { loading, gameUrl } = this.state
    // console.log(gameUrl)

    return (
      <div style={{ width: "100vw", height: "100vh"}}>
        {
        !loading && (
          <iframe
            title="Live Bingo" 
            style={{ width: "100%", height: "100%" }} src={gameUrl}></iframe>
        )
        }
      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(Tombala)
