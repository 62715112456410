import React from 'react'
import { connect } from 'react-redux'
import { Redux, WebClient, constants } from '../../../components'

class IdlePage extends React.Component {

  componentDidMount() {

  } 

  render() {
    return (
      <div className="h-full w-full flex items-center justify-center flex-col">
        <p className="text-white text-2xl font-roboto font-black mb-4">SLEEP MODE</p>
        <p className="text-white text-xl font-roboto mb-8">Press any menu or close button to continue.</p>
        <button 
         onClick={() => { this.props.onClose() }}
         className="px-10 py-3 bg-livestart text-white text-lg font-roboto font-bold uppercase rounded-md">
         Close
        </button>
      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      member: state.member,
      bingo_url: state.bingo_url,
      language: state.language
  }
}

export default connect(msp)(IdlePage)
