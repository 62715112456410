import React from 'react'
import { connect } from 'react-redux'
import { WebClient, constants } from '../../../components'
import LeftList from './components/left-list'
import RightList from './components/right-list'
import SubItemList from './components/sub-item-list'
import CIframe from './components/iframe'

class SlotGames extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      activeServiceId: "",
      activeSubServiceId: "",
      showIframe: false,
      categories: [],
      subItems: [],
      items: [],
      gameUrl: null
    }
  } 

  componentDidMount() {
    this._getLiveCasinos()
  }

  _getLiveCasinos() {
    let cc = new WebClient();
    cc.post(constants.uris.csno, 
      {
        serviceId: "5"
      }, 
      {
        setReqId: false,
        setMachineId: false,
        setUserId: true,
        setLang: true
      }).then((response) => {
        // console.log(response)
        if (response.status) {

          let activeServiceId = "";

          if (response?.results?.length > 0) {
            this._getItems(response.results[0].serviceId)
            activeServiceId = response.results[0].serviceId
          }

          this.setState({categories: response.results, loading: false, activeServiceId})
        }
      }).catch((err) => {
          // console.log(err)
      })
  }

  _getItems(serviceId) {
    this.setState({activeServiceId: serviceId, items: []})
    let cc = new WebClient();
    cc.post(constants.uris.csno, 
      {
        serviceId
      }, 
      {
        setReqId: false,
        setMachineId: false,
        setUserId: true,
        setLang: true
      }).then((response) => {
        // console.log(response)
        if (response.status) {
          this.setState({items: response.results})
        }
      }).catch((err) => {
          // console.log(err)
      })
  }

  _getGame(item) {
    // console.log(item)
    let cc = new WebClient();
    cc.post(constants.uris.opcs, 
      {
        cmd: "sessionStart",
        masterServiceId: item.topServiceId2,
        serviceId: item.serviceId
      }, 
      {
        setUserId: true,
        setLang: true,
        setToken: true
      }).then((response) => {
        // console.log(response)
        if (response.status) {
          this.setState({showIframe: true, gameUrl: response.content.gameurl})
        }
      }).catch((err) => {
          // console.log(err)
      })    
  }

  render() {

    const { categories, items, subItems, activeServiceId, activeSubServiceId, showIframe, gameUrl } = this.state

    return (
      <div className="w-full h-full">
        <div className="c-c">

          <LeftList 
            onItemClick={(e) => { this._getItems(e.serviceId) }}
            categories={categories} 
            activeServiceId={activeServiceId}
          />

          <div className="r-w-arrows">
              
            <SubItemList 
              onItemClick={(e) => { this._getItems(e.serviceId) }}
              subItems={subItems} 
              activeSubServiceId={activeSubServiceId} 
            />

            <RightList 
              items={items} 
              subItems={subItems} 
              onItemClick={(e) => { this._getGame(e) }} 
            />

          </div>
        </div>

        {
        showIframe && (
          <CIframe gameUrl={gameUrl} onClose={() => { this.setState({showIframe: false, gameUrl: null}) }} />
        )
        }

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(SlotGames)
