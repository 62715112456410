import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
// import * as Redux from "../../../components/redux";
import TerminalLogin from "./login-form-terminal";
import FillialeLogin from "./login-form-filliale";

class Login extends React.Component {
  render() {
    const { config } = this.props;
    // console.log(config)
    return (
      <div className="login-container">
      <h1 id="carlosloadingscreen" style={{ color: 'white' }}>Carlos Kiosk Loading</h1>
    </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member,
  };
};

export default connect(msp)(injectIntl(Login));