import constants from "../utils/constants_epics";
import { store } from "../redux";
import avro from "avsc/etc/browser/avsc-types";
import avro_scheme from "../avro-scheme/match.json";
import { Buffer } from 'buffer';

export default class SocketUtil {
  constructor() {
    this.socket = null;
    this.counter = 0;
    this.interval = null;
    // Event listeners için map
    this.listeners = new Map();
  }

  init() {
    this.connect();
  }

  // Event listener ekleme metodu
  on(eventName, callback) {
    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, []);
    }
    this.listeners.get(eventName).push(callback);
  }

  // Event listener silme metodu
  off(eventName, callback) {
    if (!this.listeners.has(eventName)) return;
    const listeners = this.listeners.get(eventName);
    this.listeners.set(
      eventName,
      listeners.filter(listener => listener !== callback)
    );
  }

  // Event tetikleme metodu
  emit(eventName, data) {
    if (!this.listeners.has(eventName)) return;
    this.listeners.get(eventName).forEach(callback => {
      try {
        callback(data);
      } catch (error) {
        console.error(`Error in ${eventName} event handler:`, error);
      }
    });
  }

  connect() {
    const socketUrl = `wss://btrd.rhk4044${constants.base_url.replace(
      "https://api.ibaterm",
      ""
    )}/wsgo`;
    this.socket = new WebSocket(socketUrl);
    this.socket.binaryType = "arraybuffer";
    
    this.socket.onopen = (evt) => {
      this.onOpen(evt);
      this.emit('open', evt);
    };
    
    this.socket.onclose = (evt) => {
      this.onClose(evt);
      this.emit('close', evt);
    };
    
    this.socket.onmessage = (evt) => {
      this.onMessage(evt);
    };
    
    this.socket.onerror = (evt) => {
      this.onError(evt);
      this.emit('error', evt);
    };
  }

  reset() {
    if (this.socket) {
      this.socket.close();
      this.connect();
    }
  }

  onOpen(evt) {
    this.timer();
  }

  onClose(evt) {
    this.clearTimer();
  }

  onMessage(evt) {
    const schema = avro.parse(avro_scheme);
    try {
      const match = schema.fromBuffer(Buffer.from(evt.data, 'binary'));
      this.processData(match);
      // Event olarak da gönder
      this.emit('data', match);
    } catch (error) {
      console.error('Error processing message:', error);
    }
  }

  onError(evt) {
    console.log("on error", evt);
  }

  disconnect() {
    this.socket?.close();
    this.clearTimer();
  }

  processData(f) {
    try {
      let d = store.getState().live_matches;
      let e = d?.filter((x) => x.id == f?.matchid);
      if (e?.length > 0) {
        e = e[0];
        e.update(f);
      }
    } catch (error) {
      console.error('Error processing data:', error);
    }
  }

  timer() {
    this.counter = 0;
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => {
      let a = this.counter;
      this.counter = a + 1;
      if (this.counter == 20) this.reset();
    }, 1000);
  }

  clearTimer() {
    if (this.interval) clearInterval(this.interval);
  }
}