import React from "react";
import { connect } from "react-redux";
import { Redux } from "../../../../components";
import { injectIntl } from "react-intl";
import _ from "lodash";

class CouponMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unique: props.o.uq,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps?.cid == this.state.unique) {
      return true;
    }

    return false;
  }

  _removeAnimate(o) {
    this.timeout = setTimeout(() => {
      o.an = "";
      Redux.setCid(o.uq);
      clearTimeout(this.timeout);
    }, 800);
  }

  render() {
    const { intl, o, item, locked, totalScore } = this.props;
    return (
      <div className="coupon-item-odd relative">
        <span>
          {o.tyn
            .replace("{pw}", `${totalScore}.`)
            .replace(/\{.*?\}/g, "")
            .replace(/\(.*?\)/, "")}
          -{" "}
          {(o.n == "1" ? item.h : o.n == "2" ? item.a : o.n)
            .replace(/\{.*?\}/g, "")
            .replace(/\(.*?\)/, "")}{" "}
          {o.spv ? `(${o.spv})` : null}
        </span>
        <div className="flex flex-row items-center">
          {o?.an && !locked && (
            <div className={`coupon-odd-animate ${o.an} mr-1`}>
              <i
                className={`fas fa-chevron-${o.an == "inc" ? "up" : "down"}`}
              />
              {this._removeAnimate(o)}
            </div>
          )}
          {locked ? (
            <span>
              <i className="fas fa-lock" />
            </span>
          ) : (
            <span>{o.o}</span>
          )}

          <div
            onClick={() => {
              this.props.reset();
              Redux.addOrRemoveOdd(item, o, intl);
            }}
            className="ml-2 text-md"
          >
            <i className="fas fa-times text-red-600" />
          </div>
        </div>
      </div>
    );
  }
}

const msp = (state) => {
  return {
    cid: state._cid,
  };
};

export default connect(msp)(injectIntl(CouponMatch));
