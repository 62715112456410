import React from 'react';
import ConfigWindow from './project/windows/config';
import MainWindow from './project/windows/main';
import { ConfigIntlProviderWrapper } from './project/components/intl-providers/config-intl-provider';
import { MainIntlProviderWrapper } from './project/components/intl-providers/main-intl-provider';

const JackpotHeader = () => (
  <header className="jackpot-header">
    <div className="jackpot-container">
      <div className="jackpot-box daily">
        <div className="star">⭐</div>
        <div className="jackpot-title">Daily Jackpot</div>
        <div className="jackpot-amount">
          <span className="jackpot-currency">₺</span>
          25,487
        </div>
      </div>

      <div className="jackpot-box weekly">
        <div className="star">⭐</div>
        <div className="jackpot-title">Weekly Jackpot</div>
        <div className="jackpot-amount">
          <span className="jackpot-currency">₺</span>
          158,965
        </div>
      </div>

      <div className="jackpot-box grand">
        <div className="star">⭐</div>
        <div className="jackpot-title">Grand Jackpot</div>
        <div className="jackpot-amount">
          <span className="jackpot-currency">₺</span>
          1,254,789
        </div>
      </div>
    </div>
  </header>
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showIframe: false,
      timer: null,
      lastActivity: Date.now(),
      showJackpot: true // Jackpot gösterimi için state
    };
    this.idleTime = 10000; // 10 saniye
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.resetTimer);
    document.addEventListener('mousedown', this.resetTimer);
    document.addEventListener('keypress', this.resetTimer);
    document.addEventListener('touchstart', this.resetTimer);
    document.addEventListener('touchmove', this.resetTimer);
    document.addEventListener('scroll', this.resetTimer);
    this.addJackpotStyles();
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.resetTimer);
    document.removeEventListener('mousedown', this.resetTimer);
    document.removeEventListener('keypress', this.resetTimer);
    document.removeEventListener('touchstart', this.resetTimer);
    document.removeEventListener('touchmove', this.resetTimer);
    document.removeEventListener('scroll', this.resetTimer);

    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
  }

  addJackpotStyles = () => {
    const styles = `
      .jackpot-header {
        background: linear-gradient(45deg, #1a1a2e, #16213e);
        padding: 8px;
        font-family: 'Arial', sans-serif;
        color: white;
        border-bottom: 2px solid #ffd700;
      }

      .jackpot-container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        gap: 12px;
      }

      .jackpot-box {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        padding: 8px 12px;
        text-align: center;
        min-width: 220px;
        position: relative;
        overflow: hidden;
        border: 1px solid rgba(255, 255, 255, 0.1);
      }

      @keyframes pulse-border {
        0% {
          box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
        }
        70% {
          box-shadow: 0 0 0 6px rgba(255, 215, 0, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
        }
      }

      .star {
        position: absolute;
        top: 5px;
        right: 8px;
        font-size: 14px;
        animation: star-pulse 1.5s ease-in-out infinite;
      }

      @keyframes star-pulse {
        0% {
          transform: scale(1);
          opacity: 0.8;
        }
        50% {
          transform: scale(1.2);
          opacity: 1;
        }
        100% {
          transform: scale(1);
          opacity: 0.8;
        }
      }

      .daily {
        border-top: 2px solid #4CAF50;
        animation: pulse-border 2s infinite;
      }

      .weekly {
        border-top: 2px solid #2196F3;
        animation: pulse-border 2s infinite;
        animation-delay: 0.3s;
      }

      .grand {
        border-top: 2px solid #FFC107;
        animation: pulse-border 2s infinite;
        animation-delay: 0.6s;
      }

      .jackpot-box:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 10px rgba(0,0,0,0.4);
        transition: all 0.3s ease;
      }

      .jackpot-title {
        font-size: 0.9rem;
        margin-bottom: 4px;
        text-transform: uppercase;
        font-weight: bold;
        animation: glow 2s ease-in-out infinite alternate;
        letter-spacing: 0.5px;
      }

      @keyframes glow {
        from { text-shadow: 0 0 3px #fff, 0 0 5px #fff, 0 0 8px #ffd700; }
        to { text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ffd700; }
      }

      .jackpot-amount {
        font-size: 1.8rem;
        font-weight: bold;
        margin: 2px 0;
        text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
        line-height: 1;
      }

      .jackpot-currency {
        color: #ffd700;
        font-size: 0.7em;
      }

      @media (max-width: 768px) {
        .jackpot-container {
          flex-direction: column;
          align-items: center;
        }
        
        .jackpot-box {
          width: 90%;
          padding: 6px 10px;
        }

        .star {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    `;

    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }

  startTimer = () => {
    const timer = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - this.state.lastActivity >= this.idleTime) {
        this.setState({ showIframe: true });
      }
    }, 1000);
    this.setState({ timer });
  };

  resetTimer = () => {
    this.setState({
      lastActivity: Date.now(),
      showIframe: false
    });
  };

  render() {
    if (this.state.showIframe) {
      return (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999
        }}>
          <iframe
            src="https://www.google.com"
            style={{
              width: '100%',
              height: '100%',
              border: 'none'
            }}
            title="Google"
          />
        </div>
      );
    }

    return (
      <>
        {this.state.showJackpot && <JackpotHeader />}
        <MainIntlProviderWrapper>
          <MainWindow />
        </MainIntlProviderWrapper>
      </>
    );
  }
}

export default App;