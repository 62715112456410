import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  WebClient,
  constants,
  getUserData,
} from "../../../components/utils/webclient";
import * as Redux from "../../../components/redux";
import Swal from "sweetalert2/src/sweetalert2.js";

class TerminalLogin extends React.Component {
  state = {
    username: "",
    
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const username = urlParams.get("username") || "";
    // const password = urlParams.get("password") || "";
    const TerminalId = urlParams.get("TerminalId") || "";
    console.log(TerminalId);
    if(!localStorage.getItem("TerminalId") || TerminalId !== localStorage.getItem("TerminalId"))
    {
      localStorage.setItem("TerminalId", TerminalId);
    }

    
    this.setState({ username }, () => {
      if (username) {
        this._login();
      }
    });
  }

  _login() {
    const { username } = this.state;
    const wc = new WebClient();
    wc.post(
      constants.uris.logn,
      {
        userName: username,
        passwordType: "",
      },
      {
        setReqId: true,
        setMachineId: true,
        setOperatorId: true,
        setLang: true,
        setServerId: true,
        setUserId: true,
      }
    )
      .then((response) => {
        if (response.status) {
          Redux.setTerminalPass(response.terminalPass);
          localStorage.setItem("uname", username);
          getUserData(2, response.userId);
          return;
        }
      
      })
      .catch((error) => {
        Swal.fire({
          title: this.props.intl.formatMessage({
            id: "lang_error",
            defaultMessage: "lang_error",
          }),
          text: this.props.intl.formatMessage({
            id: "lang_an_error_occured",
            defaultMessage: "lang_an_error_occured",
          }),
          icon: "danger",
          timer: 1500,
          showCancelButton: false,
          showConfirmButton: false,
        });
      });
  }

  render() {
    return null;
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member,
  };
};

export default connect(msp)(injectIntl(TerminalLogin));