import React from 'react'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, emitTerminalMessageremote, getUserData } from '../../../components'

class LiveBingo extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      lastSentMessage: null,
    }
    this.handleIframeTask = this.handleIframeTask.bind(this);
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    console.log("LiveBingo mounted")
    this.addEventListener();
  }

  componentWillUnmount() {
    this.removeEventListener();
  }

  addEventListener() {
    window.addEventListener('message', this.handleIframeTask);
  }

  removeEventListener() {
    window.removeEventListener('message', this.handleIframeTask);
  }

  handleIframeTask = (e) => {
    console.log("Received message:", e);
    // Güvenlik kontrolü: Sadece beklenen kaynaktan gelen mesajları işle
    if (e.origin !== "https://embed.livebingo.io") {
      return;
    }

    if (e.data && typeof e.data === 'object') {
      console.log("Message data:", e.data);
      if (e.data.credit !== undefined) {
        this._sendCouponToPrint(e.data);
      }
    }
  };

  navigateIframe(url) {
    if (this.iframeRef.current) {
      this.iframeRef.current.src = url;
    }
  }

  _sendCouponToPrint(data) {
    let u = new URLSearchParams(data).toString();
    let url = constants.prints.bingoprint
      .replace("{{userId}}", this.props.member.userId)
      .replace("{{lang}}", this.props.language)
      .replace('{{paramaters}}', u)
    
    console.log("bingo -->", url)
    console.log("bingo -->", data.transaction_id)
    console.log("bingo -->", `bingo_coupon_${data.transaction_id}`)

    // Yeni mesajı oluştur
    const newMessage = {
      terminalId: "1778734717",
      type: "BingoPrint",
      coupon: url
    };

    // Eğer yeni mesaj son gönderilen mesajla aynı değilse gönder
    if (JSON.stringify(newMessage) !== JSON.stringify(this.state.lastSentMessage)) {
      emitTerminalMessageremote('BingoPrint', { coupon: url });
      getUserData();

      // Son gönderilen mesajı güncelle
      this.setState({ lastSentMessage: newMessage });
    } else {
      console.log("Duplicate message, not sending.");
    }
  }

  handleIframeLoad = () => {
    this.setState({ loading: false });
    console.log("iframe loaded");
  }

  render() {
    console.log("LiveBingo render")
    const { loading } = this.state
    const { bingo_url } = this.props
    const iframeUrl = `${bingo_url}${bingo_url.includes('?') ? '&' : '?'}embedded=true`;

    return (
      <div className="h-full w-full">
        {loading && <div>Loading...</div>}
        <iframe
          ref={this.iframeRef}
          key={iframeUrl}
          name="bingo-iframe"
          frameBorder={0}
          style={{ width: "100%", height: "100%" }}
          src={iframeUrl}
          allow="autoplay; fullscreen; microphone; camera; payment"
          allowFullScreen
          onLoad={this.handleIframeLoad}
        ></iframe>
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member,
    bingo_url: state.bingo_url,
    language: state.language
  }
}

export default connect(msp)(LiveBingo)