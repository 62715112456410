import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { FormattedMessage } from "react-intl"
import { Button } from '../../components'
import classNames from 'classnames'
import Select from 'react-select';
import "../../../assets/common/config.css"
import { toast } from 'react-toastify'
import NumpadInput from "./numpad-input"

// import { IntlContext } from '../../components/intl-providers/config-intl-provider'

class App extends React.Component {

  constructor(props) {
    super(props)

    let channels = []

    try {
      if (props.config?.settings?.channels) {
        channels = JSON.parse(props.config.settings.channels)
      }
    } catch {}

    this.state = {
      screenOptions: [
        {value: "Main", label: "Main"},
        {value: "LiveMatches", label: "LiveMatches"},
        {value: "TombalaVideo", label: "TombalaVideo"}
      ],
      secondScreenOptions: [
        {value: "", label: "---"},
        {value: "Main", label: "Main"},
        {value: "LiveMatches", label: "LiveMatches"},
        {value: "TombalaVideo", label: "TombalaVideo"}
      ],      
      tabs: ["Display"],
      activeTab: "Display",
      config: props.config.settings,
      change: false,
      moneys: [
        {value: "", label: "-"},
        {value: "0.1", label: "0.1"},
        {value: "0.2", label: "0.2"},
        {value: "0.5", label: "0.5"},
        {value: "1", label: "1"},
        {value: "2", label: "2"},
        {value: "5", label: "5"},
        {value: "10", label: "10"},
        {value: "20", label: "20"},
        {value: "50", label: "50"},
        {value: "100", label: "100"},
        {value: "200", label: "200"},
        {value: "500", label: "500"},
      ],
      channels: [
        {value: "", label: "-"},
        {value: "0", label: "0"},
        {value: "1", label: "1"},
        {value: "2", label: "2"},
        {value: "3", label: "3"},
        {value: "4", label: "4"},
        {value: "5", label: "5"},
        {value: "6", label: "6"},
        {value: "7", label: "7"},
        {value: "8", label: "8"},
        {value: "9", label: "9"},
        {value: "10", label: "10"},
        {value: "11", label: "11"},
        {value: "12", label: "12"},
      ],            
      selectedCoinChannels: [],
      selectedBillChannels: [],      
      selectedMoney: null,
      selectedChannel: null,
      list: channels
    }
  }

  componentDidMount() {
  
  }

  setConfig(param, value) {
    let a = {...this.state.config}
    a[param] = value;
    this.setState({config: a})
  }

  saveConfig(close = false) {
    let a = this.state.config

    if (a.mainScreen == undefined) {
      a.mainScreen = "Main"
    }

    let b = JSON.stringify(a)
    console.log("saveConfig", b)
    toast.success(this.props.intl.formatMessage({id: "config_successfully_saved"}))
    if (close) console.log("closeConfigWindow", this.props.config)
  }

  close() {
    console.log("closePrefsWindow", this.props.config)
  }  

  render() {
    const { config } = this.props
    const { tabs, activeTab, screenOptions, secondScreenOptions } = this.state

    const height = window.innerHeight
    const title_height = 70
    const button_height = 56

    return (
      <div className="w-screen h-screen bg-white" style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
        <div className="w-full border-b border-l border-r border-solid border-cgray-300 bg-cgray-100" style={{ height: height - title_height - 10 }}>

          <div className="w-full bg-white flex flex-row items-center justify-between border-b border-solid border-cgray-300 relative" style={{ height: button_height, fontSize: 22, fontWeight: "bold" }}>
            <FormattedMessage id="Terminal Preferences" />  
            <div className="flex flex-row h-full">
              <button onClick={() => { this.setState({activeTab: "Display"}) }} className={classNames("tabs-button", {"selected": activeTab == "Display"})}><FormattedMessage id="display_settings" /></button>
              <button onClick={() => { this.setState({activeTab: "Printer"}) }} className={classNames("tabs-button", {"selected": activeTab == "Printer"})}><FormattedMessage id="printer_settings" /></button>
              <button onClick={() => { this.setState({activeTab: "MoneyAcceptor"}) }} className={classNames("tabs-button", {"selected": activeTab == "MoneyAcceptor"})}><FormattedMessage id="money_acceptor_settings" /></button>
            </div>
            <div className="absolute h-full bg-white" style={{ width: 2, left: -1 }}></div>
          </div>  

          {
          activeTab == "Display" && (
          <div className="w-full p-4" style={{ height: height - title_height - button_height - 10 }}>

            <p style={{ fontSize: 24 }}><FormattedMessage id="Total Available Display" /> : <strong>{config.displayCount}</strong></p>
            <hr className="my-5" />
            
            <span className="w-100 block mb-3"><FormattedMessage id="Main Display" /></span>
              <Select
                className="mb-6"
                placeholder={<FormattedMessage id="Main Display" />}
                defaultValue={screenOptions.filter(x => x.value == this.state.config.mainScreen)[0] ?? screenOptions[0]}
                value={screenOptions.filter(x => x.value == this.state.config.mainScreen)[0] ?? screenOptions[0]}
                options={screenOptions}
                onChange={(e) => {  
                  this.setConfig("mainScreen", e.value) 
                }}
              />   
            
            {
            config.hasExternalDisplay && (
              <>
              <span className="w-100 block mb-3"><FormattedMessage id="Second Display" /></span>
              <Select
                placeholder={<FormattedMessage id="Second Display" />}
                defaultValue={secondScreenOptions.filter(x => x.value == this.state.config.secondScreen)[0] ?? ""}
                value={secondScreenOptions.filter(x => x.value == this.state.config.secondScreen)[0] ?? ""}
                options={secondScreenOptions}
                onChange={(e) => { 
                  this.setConfig("secondScreen", e.value) 
                }}
              />
              </>
            )
            }                    

          </div>            
          )
          }

          {
          activeTab == "Printer" && (
          <div className="w-full p-4" style={{ height: height - title_height - button_height - 10 }}>

            <p style={{ fontSize: 24 }}><FormattedMessage id="Select Default Printer" /></p>
            <hr className="my-5" />

            {
            config.printers.map((printer, index) => {
              return (
                <div key={index} 
                  onClick={() => { this.setConfig("defaultPrinter", printer.displayName) }}
                  className="w-full bg-white border border-solid border-cgray-500 flex flex-row items-center justify-start" style={{ height: 48 }}>
                  <div className="h-full flex items-center justify-center" style={{ width: 48, height: 48 }}>
                    {
                    this.state.config?.defaultPrinter == printer.displayName && (
                      <i className="fas fa-check" />
                    )
                    }
                  </div>
                  <div style={{ fontSize: 18 }}>{printer.displayName}</div>
                </div>
              )
            })
            }                                

          </div>            
          )
          } 

          {
          activeTab == "MoneyAcceptor" && (
          <div className="w-full p-4" style={{ height: height - title_height - button_height - 10 }}>

            <p style={{ fontSize: 24 }}><FormattedMessage id="Set Channels" /></p>
            <hr className="my-5" />

            <div className="w-full flex flex-row items-start justify-start">

              <div className="w-1/2">
                <div className="acc-row">
                  <span>Value</span>
                  <span>Channel</span>
                </div>

                <div className="acc-row">
                  <Select
                    className="w-1/2"
                    placeholder={<FormattedMessage id="Value" />}
                    value={this.state?.selectedMoney ?? this.state.moneys[0]}
                    options={this.state.moneys}
                    onChange={(e) => { 
                      this.setState({selectedMoney: e})
                    }}
                  /> 
                  <Select
                    className="w-1/2"
                    placeholder={<FormattedMessage id="Value" />}
                    value={this.state?.selectedChannel ?? this.state.channels[0]}
                    options={this.state.channels}
                    onChange={(e) => { 
                      this.setState({selectedChannel: e})
                    }}
                  />                                    
                </div>
                <div className="acc-row" style={{ padding: 0 }}>
                  <button 
                    onClick={() => {
                      const { selectedChannel, selectedMoney, list } = this.state
                      let v = selectedMoney
                      let c = selectedChannel
                      if (v != null && c != null) {
                        let a = list
                        if (a.find(x => (x.value == v.value || x.channel == c.value) && x.type == "Coin")) {
                          alert("Already added!")
                        } else {
                          a.push({
                            value: v.value,
                            channel: c.value,
                            type: "Coin",
                            id: `C${c.value}`
                          })
                          this.setState({list: a, selectedChannel: null, selectedMoney: null})
                          this.setConfig("channels", JSON.stringify(a))
                        }
                      }
                    }}
                    className="w-1/2 h-full text-white bg-cgreenend border-0">Add Coin Channel</button> 
                  <button 
                    onClick={() => {
                      const { selectedChannel, selectedMoney, list } = this.state
                      let v = selectedMoney
                      let c = selectedChannel
                      if (v != null && c != null) {
                        let a = list
                        if (a.find(x => (x.value == v.value || x.channel == c.value) && x.type == "Banknote")) {
                          alert("Already added!")
                        } else {
                          a.push({
                            value: v.value,
                            channel: c.value,
                            type: "Banknote",
                            id: `B${c.value}`
                          })
                          this.setState({list: a, selectedChannel: null, selectedMoney: null})
                          this.setConfig("channels", JSON.stringify(a))
                        }
                      }
                    }}                    
                    className="w-1/2 h-full text-white bg-matchesend border-0">Add Banknote Channel</button>
                </div>                
              </div>

              <div className="w-1/2 pl-4">
                <p style={{ fontSize: 18 }} className="mb-4 font-bold">List</p>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th className="text-left">Value</th>
                      <th className="text-left">Channel</th>
                      <th className="text-left" style={{ width: 80 }}>Type</th>
                      <th className="text-left" style={{ width: 50 }}>#</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    this.state.list.map((e, index) => {
                      return (
                      <tr key={index} className="py-2">
                        <td>{e.value}</td>
                        <td>{e.channel}</td>
                        <td>{e.type}</td>
                        <td>
                          <button 
                            onClick={() => {
                              let a = this.state.list
                              let b = a.indexOf(e)
                              a.splice(b, 1)
                              this.setState({list: a})
                              this.setConfig("channels", JSON.stringify(a))
                            }}
                            className="py-2 bg-red-600 text-white" style={{ width: 50 }}>
                            x
                          </button>
                        </td>
                      </tr>
                      )
                    })
                    }
                  </tbody>
                </table>
              </div>              

            </div>                               

          </div>            
          )
          }                    


        </div>

        <div className="w-full flex flex-row items-center justify-end" style={{ height: title_height, fontSize: 24 }}>
          <button onClick={() => { this.saveConfig(false) }} className="bottom-button"><FormattedMessage id="save" /></button>
          <button onClick={() => { this.saveConfig(true) }} className="bottom-button"><FormattedMessage id="save_and_restart" /></button>
          <button onClick={() => { this.close() }} className="bottom-button"><FormattedMessage id="close_without_saving" /></button>
        </div>        

      </div>
    )
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(injectIntl(App))
