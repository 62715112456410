import React from 'react'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, UpDown, CFormatDate, CButton } from '../../../../components'
import { FormattedMessage, injectIntl, FormattedDate, FormattedTime } from 'react-intl'
import classNames from 'classnames'
import Swal from 'sweetalert2/src/sweetalert2.js'
import moment from 'moment'

let divHeight = 0
let pages = 1
let activePage = 1
let limit = 0

class AdminAccounts extends React.Component {

  constructor(props) {
    super(props)
    this.wc = new WebClient();
    this.state = {
      currentAccount: null,
      localAccount: null,
      oldbills: []
    }
  } 

  componentDidMount() {
    this._getUserBills()
  }

  _getUserBills() {

    if (this.state.activeTab != 0) {
      this.wc.post(constants.uris.usrb, 
        {
          siteType: "1"
        }, 
        {
          setReqId: true,
          setUserId: true,
          setLang: true
        }).then((response) => {
          // console.log(response)
          
          if (response.status) {
            this.setState({activeTab: 0, localAccount: response.localAccount, currentAccount: response.currentAccount, oldbills: response?.oldbills ?? []})
          } else {
            Redux.showToast(
              "error",
              response.msg
            )
          }
  
        }).catch((err) => {
            // console.log(err)
        })
    }

  }

  _resetAccount() {
    Swal.fire({
        title: this.props.intl.formatMessage({id: "lang_reset_account", defaultMessage:"lang_reset_account"}),
        text: this.props.intl.formatMessage({id: "lang_are_you_sure_you_want_to_reset_the_account", defaultMessage:"lang_are_you_sure_you_want_to_reset_the_account"}),
        icon: 'warning',
        confirmButtonText: this.props.intl.formatMessage({id: "lang_approve", defaultMessage:"lang_cancel"}),
        denyButtonText: this.props.intl.formatMessage({id: "lang_cancel", defaultMessage:"lang_cancel"}),
        showDenyButton: true,
    }).then((response) => {
        // console.log(response)
        if (response.isConfirmed) {
          
          this.wc.post(constants.uris.rsta, 
            {
              siteType: "1"
            }, 
            {
              setReqId: true,
              setUserId: true,
              setLang: true
            }).then((response) => {
              // console.log(response)
              
              if (response.status) {
                Redux.showToast(
                  "success",
                  response.msg
                )
                this._getUserBills()
              } else {
                Redux.showToast(
                  "error",
                  response.msg
                )
              }
      
            }).catch((err) => {
                // console.log(err)
            })          

        }
    })
  }   

  render() {
    const { currentAccount, localAccount, oldbills } = this.state
    return (
        <>
        <div className="manager-content">
            {
            (localAccount !== undefined && localAccount !== null) && (
            <>
            <div className="accounts-title">
              <FormattedMessage id="lang_all_accounts" defaultMessage="lang_all_accounts" />
            </div>

            <table className="ticket-result-table mb-4">
                <thead>
                    <tr>
                        <th><FormattedMessage id="lang_transfer_in" defaultMessage="lang_transfer_in" /></th>
                        <th><FormattedMessage id="lang_transfer_out" defaultMessage="lang_transfer_out" /></th>
                        <th><FormattedMessage id="lang_in" defaultMessage="lang_in" /></th>
                        <th><FormattedMessage id="lang_out" defaultMessage="lang_out" /></th>
                        <th><FormattedMessage id="lang_cancel" defaultMessage="lang_cancel" /></th>
                        <th><FormattedMessage id="lang_total" defaultMessage="lang_total" /></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{localAccount?.transfers?.amountA ?? 0}</td>
                        <td>{localAccount?.transfers?.amountE ?? 0}</td>
                        <td>{localAccount?.data?.amountE ?? 0}</td>
                        <td>{localAccount?.data?.amountA ?? 0}</td>
                        <td>{localAccount?.data?.cancel ?? 0}</td>
                        <td>{localAccount?.data?.total ?? 0}</td>
                    </tr>
                </tbody>
            </table>
            </>
            )}

            {
            (currentAccount !== undefined && currentAccount !== null) && (
            <>
            <div className="accounts-title">
              <FormattedMessage id="lang_account" defaultMessage="lang_account" />
            </div>

            <table className="ticket-result-table mb-4">
                <thead>
                    <tr>
                        <th><FormattedMessage id="lang_transfer_in" defaultMessage="lang_transfer_in" /></th>
                        <th><FormattedMessage id="lang_transfer_out" defaultMessage="lang_transfer_out" /></th>
                        <th><FormattedMessage id="lang_in" defaultMessage="lang_in" /></th>
                        <th><FormattedMessage id="lang_out" defaultMessage="lang_out" /></th>
                        <th><FormattedMessage id="lang_cancel" defaultMessage="lang_cancel" /></th>
                        <th><FormattedMessage id="lang_total" defaultMessage="lang_total" /></th>
                        <th><FormattedMessage id="lang_reset" defaultMessage="lang_reset" /></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{currentAccount?.totalT ?? 0}</td>
                        <td>{currentAccount?.totalT ?? 0}</td>
                        <td>{currentAccount?.amountE ?? 0}</td>
                        <td>{currentAccount?.amountA ?? 0}</td>
                        <td>{currentAccount?.cancel ?? 0}</td>
                        <td>{currentAccount?.total ?? 0}</td>
                        <td>
                          <button onClick={() => { this._resetAccount() }} className="reset-button">
                            <i className="fas fa-minus mr-2" />
                            <FormattedMessage id="close_reset" defaultMessage="close_reset" />
                          </button>
                        </td>
                    </tr>
                </tbody>
            </table> 
            </>
            )
            }
            
            {
            (oldbills?.length > 0) && (
            <>
            <div className="accounts-title">
              <FormattedMessage id="lang_old_accounts" defaultMessage="lang_old_accounts" />
            </div>

            <table className="ticket-result-table mb-4">
                <thead>
                    <tr>
                        <th><FormattedMessage id="lang_transfer_in" defaultMessage="lang_transfer_in" /></th>
                        <th><FormattedMessage id="lang_transfer_out" defaultMessage="lang_transfer_out" /></th>
                        <th><FormattedMessage id="lang_in" defaultMessage="lang_in" /></th>
                        <th><FormattedMessage id="lang_out" defaultMessage="lang_out" /></th>
                        <th><FormattedMessage id="lang_cancel" defaultMessage="lang_cancel" /></th>
                        <th><FormattedMessage id="lang_total" defaultMessage="lang_total" /></th>
                        <th><FormattedMessage id="lang_date" defaultMessage="lang_date" /></th>
                        <th><FormattedMessage id="lang_print" defaultMessage="lang_print" /></th>
                    </tr>
                </thead>
                <tbody>
                    {
                    oldbills.map((item, index) => {
                      return (
                      <tr key={index}>
                          <td>{item?.transferE ?? 0}</td>
                          <td>{item?.transferA ?? 0}</td>
                          <td>{item?.expense ?? 0}</td>
                          <td>{item?.income ?? 0}</td>
                          <td>{item?.cancel ?? 0}</td>
                          <td>{item?.total ?? 0}</td>
                          <td>
                            <CFormatDate date={item.createdAt} />
                          </td>
                          <td>
                            <button onClick={() => { this._resetAccount() }} className="print-button">
                              <i className="fas fa-print mr-2" />
                              <FormattedMessage id="lang_print" defaultMessage="lang_print" />
                            </button>
                          </td>
                      </tr>
                      )
                    })
                    }
                </tbody>
            </table> 
            </>
            )
            }
        </div>

        <UpDown 
            size="md" 
            mb="mb-0"
            px="px-0"
            upClick={() => {  }} 
            downClick={() => {  }} 
        />

        </>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(injectIntl(AdminAccounts))
