import React from "react"
import { IntlProvider } from "react-intl"

import messages_en from "../translates/config/en.json"
import messages_tr from "../translates/config/tr.json"

const menu_messages = {
  en: messages_en,
  tr: messages_tr
}

const Context = React.createContext()

class ConfigIntlProviderWrapper extends React.Component {
    
  state = {
    locale: "en",
    messages: menu_messages["en"]
  }

  render() {
    
    const { children } = this.props
    const { locale, messages } = this.state

    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: language => {
            localStorage.setItem("language", language);
            this.setState({
              locale: language,
              messages: menu_messages[language]
            })
          }
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { ConfigIntlProviderWrapper, Context as IntlContext }
