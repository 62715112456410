import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from "react-intl"
import * as Redux from '../../../components/redux' 
import printer_img from '../../../../assets/img/printer.png'

let listener_initialized = false

class PrinterError extends React.Component {

  _checkPrinterStatus() {

      if (window.apitest2 && !listener_initialized) {
       // window.apitest.invoke('printerStatusResponse', (event, args) => {
          window.apitest2.onResponse("printerStatusResponse", (args) => {
              if (args.status) {
                Redux.setPrinterError(false)
              }
          }); 
          listener_initialized = true
      }        

      if (window?.electronAPI?.ipcRenderer) {
          console.log("printerStatusRequest", "_")
      }       
  }
  
  _continue() {
    if (window?.electronAPI?.ipcRenderer) {
        console.log("mockPrinterStatus", "_")
    }       
}  

  render() {
    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center bg-cgray-800">

      <img src={printer_img} className="mb-10" style={{ width: "5%", height: "auto" }} />

      <h1 className="text-3xl font-bold text-white mb-5">YAZICIYI KONTROL EDİN</h1>

      <h1 className="text-2xl text-white mb-10">Yazıcı bağlı değil ya da kağıdı bitmiş. Lütfen yazıcınızı kontrol edin.</h1>

      <div onClick={() => { this._checkPrinterStatus() }} className="py-5 px-10 mb-4 bg-green-500 text-white">
       TEKRAR DENE
      </div>

      <div onClick={() => { this._continue() }} className="py-5 px-10 bg-green-500 text-white">
       YAZICI OLMADAN DEVAM ET
      </div>      

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      member: state.member
  }
}

export default connect(msp)(injectIntl(PrinterError)) 
