import { Redux } from "..";
import nr from "./name-replacer";
import Selection from "./selection";

class Market {
  constructor(m, match) {
    this.guid = `${match.id}-${m.oddsType}`
    if (m.oddsType) this.id = m.oddsType;
    if (m.Id) this.mid = m.Id;
    
    if (m.hasOwnProperty("iss")) {
      this.iss = match.iss ? true : m.iss
    }

    if (m.hasOwnProperty("isv")) {
      this.isv = !match.isv ? false : m.isv
    }

    if (m.special) this.sp = m.special;
    if (m.typeName) this.tyn = m.typeName;
    if (m.specialvalue) this.spv = parseFloat(m.specialvalue);
    // if (m.special?.includes('*')) {
    //   this.sp = m.special;
    //   this.spv = m.special.split("*")[0]
    // }
    if (m.sq) this.sq = m.sq;
    if (m.csh) this.csh = m.csh;
    if (m && m.id && !!match) this.n = nr(m.typeName, match, m);
    this.st = (this.iss || !this.isv) ? "locked" : ""
    this.lck = Boolean(this.iss || !this.isv)
    if (m.odds && m.odds.length) this.o = m.odds.map(s => new Selection(s, this, match));
  }
  update(m, match) {
    let _ch = false
    if (m.oddsid && this.mid != m.oddsid) {
      this.mid = m.oddsid;
      _ch = true;
    }

    // if (m.special && m.special.split("*")[0] != "" && this.spv != m.special.split("*")[0]) {
    //   this.spv = m.special.split("*")[0];
    //   _ch = true;
    // }

    // if (m.special && this.spv != m.special) {
    //   this.sp = m.special;
    //   _ch = true;
    // }

    if (m.sq && this.sq != m.sq) {
      this.sq = m.sq;
      _ch = true;
    }

    
    if (m.hasOwnProperty("iss")) {
      let niss = match.iss ? true : m.iss;
      if (this.iss != niss) {
        this.iss = niss
        _ch = true;
      }
    }

    if (m.hasOwnProperty("isv")) {
      let nisv = match.isv ? m.isv : false;
      if (this.isv != nisv) {
        this.isv = nisv
        _ch = true;
      }
    }

    if (match.bs == "stopped") {
      this.isv = false;
      this.iss = true;
      _ch = true;
    }

    let nst = Boolean(this.iss || !this.isv) ? "locked" : ""
    if (this.st != nst) {
      this.st = nst
      this.lck = Boolean(this.iss || !this.isv)
      _ch = true
    }

    if (m.odds && m.odds.length)
      this.o.forEach(selection => {
        let fSelection = m.odds.find(so => so.id == selection.sid);
        if (fSelection) selection.update(fSelection, this, match.id);
      });
    
    if (_ch) Redux.setMid(match.id)
  }
}

export default Market;
