import React from 'react'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { UpDown } from '../../../../components'

let scrollM = 0

class LeftList extends React.Component {

  constructor(props) {
    super(props)
  } 

  componentDidMount() {

  }

  render() {

    const { categories, activeServiceId } = this.props

    let height = window.innerHeight;
    let h = (height * 91.09) / 100;
    
    return (
        <div className="l-w-arrows">
        <div className="l-w-arrows-in">
            <FlatList
            style={{ width: "100%", height: "100%" }}
            data={categories}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            onScroll={(e) => {  }}
            renderItem={({item, index}) => {
                return (
                <div key={index} 
                    onClick={() => { this.props.onItemClick(item) }}
                    className={`left-list-item ${Boolean(activeServiceId == item.serviceId) && ("active")}`}>
                    {item.title}
                </div>
                )
            }}
            />
        </div>

        <UpDown size="md" upClick={() => {  }} downClick={() => {  }} disabled={Boolean(categories.length < 14)} />
        </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(LeftList)
