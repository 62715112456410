import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Redux } from "../../../../components";
import moment from "moment";
import classNames from "classnames";

let tournament_title = "";

class MatchRow extends React.Component {
  static defaultProps = {
    showTitle: false,
    index: 0,
  };

  componentDidUpdate() {
    tournament_title = "";
  }

  render() {
    const { item, schema, z, l, oe, index, coupon, intl } = this.props;
    return (
      <div className="match-row-cont">
        {schema.map((s, sindex) => {
         // console.log("schema", s);
          return (
            <div
              key={`400000${index}${sindex}`}
              data-box={z + 1}
              data-col={l}
              className={classNames({
                [`m-${s.y}`]: true,
                [`i-c-${oe}`]: true,
              })}
            >
              {s.y == "d" && (
                <>
                  <span style={{fontSize:"15px"}}>{moment(item.md).format("DD/MM")}</span>
                  <span style={{fontSize:"15px"}}>{moment(item.md).format("HH:mm")}</span>
                </>
              )}

              {s.y == "t" && (
                <div style={{backgroundColor:"#084880", borderBottom:"solid", maxHeight:"57px", borderBottomWidth:"2px", borderBottomColor:"white"}} className="w-full flex flex-row items-center justify-between px-4">
                  <span className="m-t-h">{item.h}</span>
                  <span className="m-t-s">-:-</span>
                  <span className="m-t-h">{item.a}</span>
                </div>
              )}

              {s.y == "o" && (
                <>
                  <div className={`odd-group odd-group-${s.o.length}`}>
                    {s.o.map((_o, oindex) => {
                      let o = _o;

                      let g = undefined;
                      let j = undefined;
                      let is_added = false;

                      let _g = item?.o?.filter((x) => x.id == o.t);

                      if (item.itie && o.t == 12553 && _g.length == 0) {
                        _g = item?.o?.filter((x) => x.id == 5498);
                        let _s = schema.filter((x) => x.i == 5498);
                        if (_s?.length > 0) {
                          _s = _s[0];
                          o = _s?.o[oindex] ?? _o;
                        }
                      }

                      if (_g?.length > 0) {
                        g = _g[0];

                        if (o.t == 5500) {
                          _g = _g.filter((x) => x.spv == 2.5);
                          if (_g.length > 0) {
                            g = _g[0];
                          }
                        } else if (o.t == 5500) {
                          _g = _g.filter((x) => x.spv % 1 > 0);
                          if (_g.length > 0) {
                            g = _g[0];
                          }
                        } else if (o.t == 18) {
                          _g = _g.filter((x) => x.spv % 1 == 0.5);
                          if (_g.length > 0) {
                            g = _g[0];
                          }
                        }

                        let k = g?.o?.filter((x) => x.id == o.i);

                        if (k?.length > 0) {
                          j = k[0];
                        }
                      }
                      let st = j?.st ?? "locked";

                      // console.log(j)

                      let cp = coupon.filter((x) => x.id == item.id);
                      if (cp?.length > 0) {
                        cp = cp[0];
                        is_added = Boolean(
                          cp.odd.filter((x) => x.uq == j?.uq).length > 0
                        );
                      }

                      return (
                        <div
                          key={`${j?.Id ?? oindex + 100000}-${oindex}`}
                          className={classNames({
                            "live-odd-button-cont": true,
                            "bg-not-started": true,
                          })}
                        >
                          {o.o == ".s" && (
                            <div className="odd-special">{g?.spv ?? "-"}</div>
                          )}

                          {o.o != ".s" && st == "" && (
                            <div
                              onClick={() => {
                                Redux.addOrRemoveOdd(item, j, intl);
                              }}
                              className={classNames("active-odd", {
                                "in-coupon": is_added,
                              })}
                            >
                              {j.o}
                            </div>
                          )}

                          {o.o != ".s" && st == "locked" && (
                            <div className="locked-odd"></div>
                          )}

                          {o.o != ".s" && st == "semi-locked" && (
                            <div className="semi-locked-odd">{j.o}</div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {s.y == "s" && (
                <div className="c-button-cont">
                  <button
                    disabled={!Boolean(item?.istt)}
                    onClick={() => {
                      this.props.onShowStatistics(
                        Redux.getStatisticsUrl(item.id)
                      );
                    }}
                  >
                    <i className="fas fa-chart-bar" />
                  </button>
                </div>
              )}
              {/* 
                                {
                                    s.y == "a" && (
                                        <button style={{ color: '#fff', fontSize: 24, letterSpacing: 1 }} onClick={() => { this.props.onDetail(item) }}>
                                            +{item.oc}
                                        </button>
                                    )
                                } */}
              {/* THE PLUS BUTTON */}
              {s.y == "a" && (
                <button
                  disabled={Boolean(item.oc < 1 || item.bs == "stopped")}
                  className={classNames({
                    "opacity-30": Boolean(item.oc < 1 || item.bs == "stopped"),
                  })}
                  onClick={() => {
                    this.props.onDetail(item);
                  }}
                >
                  {item.oc > 0 && item.bs != "stopped" && (
                    <span>+{item.oc}</span>
                  )}
                </button>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member,
    smatches: state.smatches,
    coupon: state.coupon,
  };
};

export default connect(msp)(injectIntl(MatchRow));
