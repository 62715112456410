import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as Redux from "../../../components/redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

let layout = {
  default: [
    '" 1 2 3 4 5 6 7 8 9 0 * - # {bksp}',
    "{tab} q w e r t y u ı o p ğ ü [ ]",
    "{lock} a s d f g h j k l ş i , {enter}",
    "{shift} < z x c v b n m ö ç . | $ € {shift}",
    ".com @ {space}",
  ],
  shift: [
    "é ! ' ^ + % & / ( ) = ? _ ~ {bksp}",
    "{tab} Q W E R T Y U I O P Ğ Ü { }",
    "{lock} A S D F G H J K L Ş İ ; {enter}",
    "{shift} > Z X C V B N M Ö Ç : \\ ` ´ {shift}",
    ".com @ {space}",
  ],
};

class FillialeLogin extends React.Component {
  state = {
    showKeyboard: false,
    edit_type: "username",
    username: "",
    password: "",
  };

  onChange = (input) => {
    if (this.state.edit_type === "username") {
      this.setState({ username: input });
    } else {
      this.setState({ password: input });
    }
  };

  onKeyPress = (button) => {
    // console.log("Button pressed", button);

    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  copyToClipboard(str) {
    navigator.clipboard.writeText(str);
    Redux.showToast("success", this.props.intl.formatMessage({ id: "Copied" }));
  }

  handleShift() {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });
  }

  render() {
    const { showKeyboard, username, password, edit_type } = this.state;
    // const { config } = this.props;
    return (
      <div className="login-form-container">
        <p className="login-title">
          <FormattedMessage id="lang_username" defaultMessage="lang_username" />
        </p>
        <div className="login-input-row">
          <div className="login-input">
            <FormattedMessage id="lang_username" defaultMessage="lang_username">
              {(message) => (
                <input
                  type="text"
                  value={username}
                  onChange={(e) => {
                    this.setState({ username: e.target.value });
                  }}
                  placeholder={message}
                />
              )}
            </FormattedMessage>
          </div>
          <button
            onClick={() => {
              this.setState({
                showKeyboard: !showKeyboard,
                edit_type: "username",
              });
            }}
            className={`keyboard-button ${
              showKeyboard &&
              edit_type === "username" &&
              "keyboard-button-active"
            }`}
          >
            <i className="far fa-keyboard" />
          </button>
        </div>
        <p className="login-title mt-4">
          <FormattedMessage id="lang_password" defaultMessage="lang_password" />
        </p>
        <div className="login-input-row">
          <div className="login-input">
            <FormattedMessage id="lang_password" defaultMessage="lang_password">
              {(message) => (
                <input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                  placeholder={message}
                />
              )}
            </FormattedMessage>
          </div>
          <button
            onClick={() => {
              this.setState({
                showKeyboard: !showKeyboard,
                edit_type: "password",
              });
            }}
            className={`keyboard-button ${
              showKeyboard &&
              edit_type === "password" &&
              "keyboard-button-active"
            }`}
          >
            <i className="far fa-keyboard" />
          </button>
        </div>
        {showKeyboard && (
          <div className="touch-keyboard-container">
            <Keyboard
              keyboardRef={(ref) => {
                this.keyboard = ref;
              }}
              layout={layout}
              onChange={this.onChange}
              onKeyPress={this.onKeyPress}
            />
          </div>
        )}
        <button
          disabled={Boolean(username.length <= 3 || password.length <= 3)}
          className="login-button"
        >
          <FormattedMessage id="lang_login" defaultMessage="lang_login" />
        </button>
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member,
  };
};

export default connect(msp)(injectIntl(FillialeLogin));
