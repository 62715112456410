import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from "react-intl"
import * as Redux from '../../../components/redux' 
import img from '../../../../assets/img/connection-error.png'


class PrinterError extends React.Component {

  _checkPrinterStatus() {
   if (navigator.onLine) {
    Redux.setConnectionError(false)
   }      
  } 

  render() {
    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center bg-cgray-800">

      <img src={img} className="mb-10" style={{ width: "10%", height: "auto" }} />

      <h1 className="text-3xl font-bold text-white mb-5">BAĞLANTI YOK</h1>

      <h1 className="text-2xl text-white mb-10">İnternet bağlantısı kurulamıyor. Lütfen kontrol edin.</h1>

      <div onClick={() => { this._checkPrinterStatus() }} className="py-5 px-10 bg-green-500 text-white">
       TEKRAR DENE
      </div>

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      member: state.member
  }
}

export default connect(msp)(injectIntl(PrinterError)) 
