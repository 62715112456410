import React from 'react'
import { FormattedMessage } from 'react-intl'

export default class TabItem extends React.Component {

  static defaultProps = {
    className: "live-matches-button",
    active: false,
    title: "live_bets",
    onClick: () => {}
  }

  render() {

    const { onClick, title, active, className } = this.props

    return (
      <div className="tab-item">
        <div onClick={onClick} className={`tab-button ${className} ${active && ("active")}`}>
          <FormattedMessage id={title} defaultMessage={title} />
        </div>
      </div>      
    )
  }
}
