import nr from "./name-replacer";
import { tF } from "../utils/webclient"
import { Redux } from "..";

class Selection {
  constructor(s, m, match) {
    this.id = s.outComeId;
    this.sid = s.Id;
    if (s.odds) this.o = tF(s.odds);
    this.iss = m.iss ? true : s.iss;
    this.isv = !m.isv ? false : s.isv;
    if (s.special) this.sp = s.special;
    // if (m.spv) this.spv = m.spv;
    if (s.special.split("*")[0]) {
      this.spv = s.special.split("*")[0]
    }
    this.st = (this.iss || !this.isv) ? "locked" : ""
    this.lck = Boolean(this.iss || !this.isv)
    this.an = ""
    this.n = nr(s.outCome, match, m);
    if (s.outCome) this.out = s.outCome
    if (s.typeName) this.tyn = s.typeName
    this.handicap = handicapper(s)
    this.ot = m.id
    this.uq = `${match.id}-${m.id}-${this.id}-${this.out}-${this?.spv ?? "*"}`
    this.guid = `${match.id}-${m.id}-${s.outCome}${this?.spv?.length > 0 ? `-${this.spv}`: ""}`
  }
  update(s, m, mid) {

    let _ch = false;

    if (s.hasOwnProperty("iss")) {
      let niss = m.iss ? true : s.iss;
      if (this.iss != niss) {
        this.iss = niss
        _ch = true
      }
    }

    if (s.hasOwnProperty("isv")) {
      let nisv = m.isv ? s.isv : false;
      if (this.isv != nisv) {
        this.isv = nisv
        _ch = true
      }
    }

    // if (m.spv != this.spv) {
    //   this.spv = m.spv;
    //   _ch = true
    // }

    if (m.iss) {
      this.iss = true;
      _ch = true;
    }

    if (!m.isv) {
      this.isv = false;
      _ch = true;
    }    

    if (this.o != s.odd) {
      let _s = "inc"
      if (this.o == s.odd) _s = ""
      if (s.odd < this.o) _s = "dec"

      this.o = tF(s.odd)
      this.an = _s
      _ch = true
    }

    let nst = Boolean(this.iss || !this.isv) ? "locked" : ""
    if (this.st != nst) {
      this.st = nst
      this.lck = Boolean(this.iss || !this.isv)
      _ch = true
    }

    if (_ch) {
      // Redux.setMid(mid)
      Redux.setCid(this.uq)
      // Redux.setJid(this.uq)
    }
    
  }
}

function handicapper(odd) {
  if (odd.oddsType == 5504) {
      //console.log(odd)
      let spv = odd.special.split("*")[0];
      if (spv != 0) {
      if (odd.outComeId == 5456) {
          if (spv < 0) {
          return "0-" + String(spv < 0 ? spv * -1 : spv);
          } else {
          return String(spv) + "-0";
          }
      }
      if (odd.outComeId == 6543) {
          if (spv < 0) {
          return "0-" + String(spv < 0 ? spv * -1 : spv);
          } else {
          return String(spv) + "-0";
          }
      }
      if (odd.outComeId == 5457) {
          if (spv > 0) {
              return "0-" + String(spv < 0 ? spv * -1 : spv);
          } else {
              return String(spv < 0 ? spv * -1 : spv) + "-0";
          }
      }
      } else {
          return "0-0";
      }
  }
  return false;
}

export default Selection;
