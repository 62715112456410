import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import CButton from '../main/main/components/button';

let layout = {
      default: [
          '1 2 3',
          "4 5 6",
          "7 8 9",
          ". 0 {bksp}"
      ]
}

let buttonTheme = [
    {
      class: "keyboard-close",
      buttons: "{close}"
    },
    {
      class: "keyboard-clear",
      buttons: "{clear}"
    },  
    {
        class: "keyboard-clear",
        buttons: "{del}"
      },        
    {
      class: "keyboard-search",
      buttons: "{search}"
    },
    {
      class: "keyboard-caps",
      buttons: "{space} {lock} {shift} {bksp}"
    },    
]

const utilizeFocus = () => {
 const ref = React.createRef()
 const setFocus = () => {ref.current &&  ref.current.focus()}

 return {setFocus, ref} 
}

class CouponNumpad extends React.Component {

   constructor(props) {
    super(props)
    this.inputFocus = utilizeFocus()
    this.state = {
     input: "",
     tab: 0,
     checkedTab: 0,
     checked: false,
     ticket: null,
     ticketContent: [],
     sellType: null,
     bingoData: null,
     cardInfo: null,
     show: false
 }    
   }


    componentDidMount() {
        this.setState({input: this.props.value})
        setTimeout(() => {
            this.keyboard.setInput(this.props.value)
        }, 500)
    }

    onChange = (input) => {
        this.setState({input})
    }

    onKeyPress = (button) => {
        const { input } = this.state

        if (button == "{bksp}") {

            let a = input.substring(0, input.length - 1)
            this.setState({input: a})

        } else {
            if (button == "." && input.includes(".")) return false
    
            let c = input.split(".")[1]
    
            if (c?.length == 2) return false
    
            let a = input + button

            let b = parseFloat(a)

            this.setState({input: a})
        }
        this.inputFocus.setFocus()
    }

    _approve() {
        this.props.onClose()
    }

    render() {
        const { input, checked, show  } = this.state
        const { intl } = this.props
        return (
        <div className="w-full h-full relative">

            <div className="w-full">
              <input ref={this.inputFocus.ref} style={{ width: "95%", height: 44 }} type="text" value={input} onFocus={() => { this.setState({show: true}) }} />
                {/* <div className="flex flex-col items-start justify-center border border-solid border-cgray-400 px-4 bg-white" style={{fontSize: 24, fontWeight: 700, height: 40}}>{input}</div> */}
            </div>

            <div className="w-full absolute" style={{ top: 50, left: -10, display: show ? 'inherit' : 'none' }}>
                <Keyboard
                    keyboardRef={(ref) => this.keyboard = ref}
                    layout={layout}
                    buttonTheme={buttonTheme}
                    maxLength={10}
                    display={{
                        "{search}": intl.formatMessage({id: "search"}),
                        "{space}": intl.formatMessage({id: "Space"}),
                        "{close}": intl.formatMessage({id: "close"}),
                        "{bksp}": intl.formatMessage({id: "<"}),
                        "{del}": intl.formatMessage({id: "<"}),
                        "{lock}": intl.formatMessage({id: "CAPS"}),
                        "{shift}": intl.formatMessage({id: "SHIFT"}),
                        "{clear}": intl.formatMessage({id: "Clear"})
                    }}
                    // onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                />
            </div>
{/* 
            <div className="w-full h-common mt-m">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="lang_approve" active={false} onClick={() => { this._approve() }} />
            </div> 

            <div className="w-full h-common mt-m">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="lang_cancel" active={false} onClick={() => { this.props.onClose() }} />
            </div>              */}

        </div>
        );
    }
}

export default injectIntl(CouponNumpad)