import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { Provider } from "react-redux";
import {
  AppRegistry,
  View,
  TouchableOpacity,
} from "react-native";
import { navigationRef } from "./project/components/nav/root-nav";
import { store } from "./project/components/redux";
import reportWebVitals from "./reportWebVitals";
import Project from "./App";
import { GetSettings } from "./project/components/utils/webclient";
import { MainIntlProviderWrapper } from "./project/components/intl-providers/main-intl-provider";
import { ToastContainer } from "react-toastify";
import Tombala from "./project/windows/tombala";
import LiveMatches from "./project/windows/live-matches";
import "./assets/fonts/fonts.css";
import "./assets/fonts/icomoon.css";
import "@fortawesome/fontawesome-free/css/solid.min.css";
import "@fortawesome/fontawesome-free/css/regular.min.css";
import "@fortawesome/fontawesome-free/css/brands.min.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Redux } from "./project/components";
//import LogRocket from 'logrocket';

// LogRocket'i başlatın
//LogRocket.init('isggwh/terminal');

// Orijinal console.error ve console.log fonksiyonlarını saklayın
const originalConsoleError = console.error;
const originalConsoleLog = console.log;

// console.error = (message, ...args) => {
//   LogRocket.captureMessage(message, {
//     level: 'error',
//     extra: args,
//   });
//   originalConsoleError(message, ...args);
// };

// console.log = (message, ...args) => {
//   LogRocket.log(message, ...args);
//   originalConsoleLog(message, ...args);
// };
const urlParams2 = new URLSearchParams(window.location.search);
const usernamea = urlParams2.get('username') || null;
const TerminalId = urlParams2.get('TerminalId') || null;
console.log(TerminalId)
if(!localStorage.getItem("TerminalId") || TerminalId !== localStorage.getItem("TerminalId"))
  {
    localStorage.setItem("TerminalId", TerminalId);
  }

  if(!localStorage.getItem("language"))
    {
      localStorage.setItem("language", 'en');
    }
// This is an example script - don't forget to change it!


const electron =null;
TouchableOpacity.defaultProps = TouchableOpacity.defaultProps || {};
TouchableOpacity.defaultProps.activeOpacity = 0.95;

class App extends React.Component {
  state = {
    list: [],
    loaded: false,
    screenType: "Main"
  };

  componentDidMount() {
    this._checkConnection();
    this._getScreenTypeFromUrl();

    if (electron == null) {
      this._getSettings();
    } else {
      document.body.classList.add("touch-device");
      const screenTypeFromElectron =
        window.process?.argv[window.process.argv.length - 1] ?? "Main";
      this.setState({ screenType: screenTypeFromElectron });
      this.setConfig();
    }
  }

  _checkConnection() {
    Redux.setConnectionError(!navigator.onLine);
  }

  _getSettings() {
    GetSettings(
      () => {
        this.setState({ loaded: true });
      },
      () => {
        this.setState({ loaded: true });
      }
    );
  }

  _getScreenTypeFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const screenType = urlParams.get('screenType') || "Main";
    this.setState({ screenType });
  }

  async setConfig() {
    const {
      windowType,
      externalDisplay,
      displayCount,
      hasExternalDisplay,
      printerCount,
      printers,
      settings,
      machineId,
      configType,
    } = await electron.remote.getGlobal("shareObject");

    let sett = {
      mainScreen: settings.mainScreen,
      secondScreen: settings.secondScreen,
      hasExternalDisplay: settings.hasExternalDisplay,
      printer: settings.printer,
      onlineCheck: settings.onlineCheck,
      defaultPrinter: settings?.defaultPrinter ?? "",
      userType: settings.userType,
      version: settings.version,
      channels: settings?.channels ?? "[]",
    };

    let config = {
      windowType,
      externalDisplay,
      displayCount,
      hasExternalDisplay,
      printerCount,
      printers,
      settings: sett,
      machineId,
      configType: configType ?? "mainConfig",
    };

    await store.dispatch({
      type: "SET_CONFIG",
      payload: config,
    });

    this._getSettings();
  }

  render() {
    const { loaded, screenType } = this.state;

    if (!loaded) return null;

    return (
      <NavigationContainer ref={navigationRef}>
        <Provider store={store}>
          <View style={{ width: "100%", height: "100%" }}>
            {screenType == "Main" && <Project />}

            {screenType == "TombalaVideo" && <Tombala />}

            {screenType == "LiveMatches" && (
              <MainIntlProviderWrapper>
                <LiveMatches />
              </MainIntlProviderWrapper>
            )}

            <ToastContainer autoClose={2000} />
          </View>
        </Provider>
      </NavigationContainer>
    );
  }
}

AppRegistry.registerComponent("App", () => App);
AppRegistry.runApplication("App", { rootTag: document.getElementById("root") });

reportWebVitals();
