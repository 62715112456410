import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import MatchRow from "./match-row";
import MatchTitle from "./match-title";
import EmptyTitle from "./empty-title";
import _ from "lodash";

let tournament_title = "";
let showTitle = true;

class MatchList extends React.Component {
  static defaultProps = {
    showTitle: false,
    index: 0,
  };

  componentDidUpdate() {
    tournament_title = "";
  }

  render() {
    const { matches, activeSport, coupon, intl, schema, z, l, dh } = this.props;
    const row_height = 58;

    return (
      <div>
        {matches.map((item, index) => {
          let oe = Boolean(index % 2 == 0) ? "odd" : "even";

          if (item?.schema) {
            return (
              <MatchTitle
                key={index}
                schema={schema}
                z={z}
                l={l}
                showTitle={true}
                title={item.title}
                flag={item.cid}
              />
            );
          } else {
            return (
              <MatchRow
                key={index}
                schema={schema}
                z={z}
                l={l}
                item={item}
                oe={oe}
                index={index}
                onDetail={(e) => {
                  this.props.onDetail(e);
                }}
                onShowStatistics={(e) => {
                  this.props.onShowStatistics(e);
                }}
              />
            );
          }
        })}
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member,
    smatches: state.smatches,
    coupon: state.coupon,
  };
};

export default connect(msp)(injectIntl(MatchList));
