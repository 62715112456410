const timeFilter = (s) => {
  // macin baslama zamanindan X dakika sonra baslamadiysa gizle
  const minafter = 5;
  if (s.matchstatus === "not_started") {
    const now = new Date();
    const mdate = new Date(s.matchDate);
    let diff = (mdate.getTime() - now.getTime()) / 1000;
    diff /= 60;
    const min = Math.round(diff);
    return min * -1 > minafter;
  } else return false;
};

export default timeFilter;
