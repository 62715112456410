import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import classNames from 'classnames'
import CButton from './button';
import { Redux, WebClient, constants, UpDown } from '../../../../components'
import moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js'

let layout = {
    default: [
        '1 2 3',
        "4 5 6",
        "7 8 9",
        ". 0 {bksp}"
    ]
}

let buttonTheme = [
    {
      class: "keyboard-close",
      buttons: "{close}"
    },
    {
      class: "keyboard-clear",
      buttons: "{clear}"
    },  
    {
        class: "keyboard-clear",
        buttons: "{del}"
    },        
    {
      class: "keyboard-search",
      buttons: "{search}"
    },
    {
      class: "keyboard-caps",
      buttons: "{space} {lock} {shift} {bksp}"
    },    
]

class CouponNumpad extends React.Component {

    state = {
        input: "",
        tab: 0,
        checkedTab: 0,
        checked: false,
        ticket: null,
        ticketContent: [],
        sellType: null,
        bingoData: null,
        cardInfo: null,
        isFirstInput: true
    }

    componentDidMount() {
        this.setState({input: this.props.keyword})
        setTimeout(() => {
            this.keyboard.setInput(this.props.keyword)
        }, 500)
    }

    componentDidUpdate(prevProps) {
        if (this.props.keyword !== prevProps.keyword) {
            this.setState({ 
                input: this.props.keyword,
                isFirstInput: true
            });
            this.keyboard.setInput(this.props.keyword);
        }
    }

    onChange = (input) => {
        // We'll handle all input changes in onKeyPress instead
    }

    onKeyPress = (button) => {
        this.setState(prevState => {
            let newInput = prevState.input;
            
            if (button === "{bksp}") {
                newInput = newInput.slice(0, -1);
                if (newInput.endsWith(".")) {
                    newInput = newInput.slice(0, -1);
                }
                if (newInput === "") {
                    return {
                        input: "0",
                        isFirstInput: true
                    };
                }
            } else {
                // For first number input, clear existing value
                if (prevState.isFirstInput && button !== ".") {
                    newInput = button;
                } else {
                    if (button === ".") {
                        if (!newInput.includes(".")) {
                            newInput += ".";
                        }
                    } else {
                        const parts = newInput.split(".");
                        if (parts.length === 1) {
                            if (prevState.isFirstInput) {
                                newInput = button;
                            } else {
                                newInput += button;
                            }
                        } else if (parts.length === 2 && parts[1].length < 2) {
                            newInput += button;
                        }
                    }
                }
            }

            // Handle empty input
            if (newInput === "") newInput = "0";

            // Update keyboard display
            this.keyboard.setInput(newInput);
            
            return { 
                input: newInput,
                isFirstInput: false
            };
        });
    }

    _approve() {
        const { maxStake, minStake } = this.props
        let a = parseFloat(this.state.input)
        Redux.updateCouponBottom({bet_amount: a})
        this.props.onClose()        
    }

    render() {
        const { input, checked  } = this.state
        const { intl, member } = this.props
        return (
        <div className="w-full">
            <div className="w-full">
                <div className="coupon-numpad-input uppercase">
                    <div className="admin-password-input">
                        <span><FormattedMessage id="lang_bet_amount" defaultMessage="lang_bet_amount" /></span>
                        <span>{input}</span>
                    </div>
                </div>
            </div>

            <div className="w-full">
                <Keyboard
                    keyboardRef={(ref) => this.keyboard = ref}
                    layout={layout}
                    buttonTheme={buttonTheme}
                    maxLength={10}
                    display={{
                        "{search}": intl.formatMessage({id: "search"}),
                        "{space}": intl.formatMessage({id: "Space"}),
                        "{close}": intl.formatMessage({id: "close"}),
                        "{bksp}": intl.formatMessage({id: "<"}),
                        "{del}": intl.formatMessage({id: "<"}),
                        "{lock}": intl.formatMessage({id: "CAPS"}),
                        "{shift}": intl.formatMessage({id: "SHIFT"}),
                        "{clear}": intl.formatMessage({id: "Clear"})
                    }}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                />
            </div>

            <div className="w-full h-common mt-m">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="lang_approve" active={false} onClick={() => { this._approve() }} />
            </div> 

            <div className="w-full h-common mt-m">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="lang_set_all_credit" active={false} onClick={() => { 
                    this.setState({
                        input: member.userCredit.toString(),
                        isFirstInput: true
                    });
                    this.keyboard.setInput(member.userCredit.toString());
                }} />
            </div>             

            <div className="w-full h-common mt-m">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="lang_cancel" active={false} onClick={() => { this.props.onClose() }} />
            </div>             

        </div>
        );
    }
}

const msp = (state) => {
    return {
        config: state.config,
        member: state.member,
        settings: state.settings,
        coupon_bottom: state.coupon_bottom
    }
}

export default connect(msp)(injectIntl(CouponNumpad))