import React from "react";

class MatchTitle extends React.Component {
  static defaultProps = {
    showTitle: false,
    index: 0,
  };

  render() {
    const { showTitle, schema, flag, title, z, l } = this.props;

    return (
      <div className="match-title-cont">
        {schema.map((s, sindex) => {
          return (
            <div
              key={sindex}
              data-box={z + 1}
              data-col={l}
              className={`t-${s.y} t-o-${s.o?.length ?? 0}`}
            >
              {s.y == "d" && <div></div>}

              {s.y == "t" && <div></div>}

              {s.y == "o" && (
                <div className="relative w-full">
                  <span className="text-center w-full">
                    {s.t?.replace("{pw}", "").split("(")[0]}
                  </span>
                  <div className="t-o-c">
                    {s.o.map((o, oindex) => {
                      return (
                        <div className="t-o-i" key={oindex}>
                          {o.o == ".s"
                            ? ""
                            : o.o
                                .replace("{$competitor1}", "1")
                                .replace("{$competitor2}", "2")
                                .replace("{total}", "")}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {s.y == "s" && <div></div>}

              {s.y == "a" && <div></div>}
            </div>
          );
        })}
      </div>
    );
  }
}

export default MatchTitle;
