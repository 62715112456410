import React from 'react'
import { connect } from 'react-redux'
import { WebClient, constants } from '../../../components'
import LeftList from './components/left-list'
import RightList from './components/right-list'
import SubItemList from './components/sub-item-list'
import CIframe from './components/iframe'

class VirtualGames extends React.Component {

  constructor(props) {
    super(props)
    this.wc = new WebClient();
    this.state = {
      loading: true,
      activeServiceId: "",
      activeSubServiceId: "",
      showIframe: false,
      gameUrl: null,
      categories: [],
      subItems: [],
      items: []
    }
  } 

  componentDidMount() {
    this._getServiceIds()
  }

  async _getServiceIds() {

    const { settings } = this.props

    if (settings != null) {

      let virtual_ids = settings.settings.virtualgames_ids.split(",")
      let i = 0;
      virtual_ids.forEach(async (id) => {
        await this._getLiveCasinos(id)
                  .then((e) => 
                    { 
                      i++;
                      if (i === virtual_ids.length) {
                        this._getFirstItems();
                      }
                    });
      })

    }

  }

  async _getLiveCasinos(id) {
    await this.wc.post(constants.uris.csno, 
      {
        serviceId: id
      }, 
      {
        setReqId: false,
        setMachineId: false,
        setUserId: true,
        setLang: true
      }).then(async (response) => {
        
        // console.log(response)
        if (response.status) {
          let b = [...this.state.categories, ...response.results]
          this.setState({categories: b})
        }

      }).catch((err) => {
          // console.log(err)
      })
  }

  _getFirstItems() {
    const { categories } = this.state

    if (categories?.length > 0) {
      let c = categories[0]
      // console.log(c)
      this._getItems(c.serviceId)
    }
  }

  _getItems(serviceId) {
    this.setState({activeServiceId: serviceId, items: []})
    let cc = new WebClient();
    cc.post(constants.uris.csno, 
      {
        serviceId
      }, 
      {
        setReqId: false,
        setMachineId: false,
        setUserId: true,
        setLang: true
      }).then((response) => {
        // console.log(response)
        if (response.status) {
          this.setState({items: response.results})
        }
      }).catch((err) => {
          // console.log(err)
      })
  }

  _getGame(item) {
    // console.log(item)
    let cc = new WebClient();
    cc.post(constants.uris.opcs, 
      {
        cmd: "sessionStart",
        masterServiceId: item.topServiceId2,
        serviceId: item.serviceId
      }, 
      {
        setUserId: true,
        setLang: true,
        setToken: true
      }).then((response) => {
        // console.log(response)
        if (response.status) {
          this.setState({showIframe: true, gameUrl: response.content.gameurl})
        }
      }).catch((err) => {
          // console.log(err)
      })    
  }  

  render() {

    const { categories, items, subItems, activeServiceId, activeSubServiceId, showIframe, gameUrl } = this.state

    return (
      <div className="w-full h-full">
        <div className="c-c">

          <LeftList 
            onItemClick={(e) => { this._getItems(e.serviceId) }}
            categories={categories} 
            activeServiceId={activeServiceId}
          />

          <div className="r-w-arrows">
              
            <SubItemList 
              onItemClick={(e) => { this._getItems(e.serviceId) }}
              subItems={subItems} 
              activeSubServiceId={activeSubServiceId} 
            />

            <RightList 
              items={items} 
              subItems={subItems} 
              onItemClick={(e) => { this._getGame(e) }} 
            />

          </div>
        </div>

        {
        showIframe && (
          <CIframe gameUrl={gameUrl} onClose={() => { this.setState({showIframe: false, gameUrl: null}) }} />
        )
        }       

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      settings: state.settings
  }
}

export default connect(msp)(VirtualGames)
