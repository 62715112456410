import React from 'react'


import moment from 'moment'

import "moment/locale/tr"
import "moment/locale/en-gb"
import "moment/locale/de"
import "moment/locale/ar"
import "moment/locale/az"


export default class Clock extends React.Component {

  state = {
    time: 0
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({time: this.state.time + 1})
    }, 1000)
  }

  componentWillUnmount() {
    if (this.interval != undefined || this.interval !== null) {
      clearInterval(this.interval)
    }
  }

  render() {
    return moment(new Date()).format('DD.MM.YYYY HH:mm:ss')
  }
}
